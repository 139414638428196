import React, { useState } from 'react';
import PublicCandidateSearch from './employer';
import JobBoard from './jobseeker';
import { Button, ButtonGroup, Grid, Typography } from '@mui/material';
// import { GenericModalWithoutAction } from '../../../../components/modal/genericModal';
// import { PublicOutlineButton, PublicContainButton } from '../../../../components/public/button';

enum selectedType {
    jobSeeker = 'jobseeker',
    employer = 'employer',
}

const DemoPage: React.FC = () => {
    const [selected, setSelected] = useState<string>(selectedType.employer);
    // const [pageSelector, setPageSelector] = React.useState<boolean>(true);

    const handleChange = (type: selectedType) => {
        setSelected(type);
    };
    return (
        <>
            <Grid container mt={'1rem'}>
                <Grid xs={12} item display={'flex'} justifyContent={'center'} alignItems={'center'} mr={'1rem'}>
                    <ButtonGroup variant="outlined" aria-label="Basic button group" fullWidth>
                        <Button variant={selected === selectedType.jobSeeker ? 'contained' : 'outlined'} onClick={() => handleChange(selectedType.jobSeeker)}>
                            <Typography variant="h5">Jobs Board </Typography>
                        </Button>
                        <Button variant={selected === selectedType.employer ? 'contained' : 'outlined'} onClick={() => handleChange(selectedType.employer)}>
                            <Typography variant="h5">Talent Search </Typography>
                        </Button>
                    </ButtonGroup>
                </Grid>
                <Grid xs={12} item>
                    {selected === selectedType.employer && <PublicCandidateSearch />}
                    {selected === selectedType.jobSeeker && <JobBoard />}
                </Grid>
            </Grid>

            {/* {pageSelector && (
                <GenericModalWithoutAction size="md">
                    <Box>
                        <Grid>
                            <Typography variant="h5" color="primary" textAlign={'center'}>
                                What do you want to do?
                            </Typography>
                        </Grid>
                        <br />
                        <Grid display={'flex'} mt={'1rem'}>
                            <PublicContainButton
                                sx={{ width: '50%', padding: '1rem', margin: '0.5rem' }}
                                onClick={() => {
                                    setPageSelector(false);
                                    setSelected(selectedType.jobSeeker);
                                }}
                            >
                                <Typography variant="h5">Find a Job</Typography>
                            </PublicContainButton>
                            <PublicOutlineButton
                                sx={{ width: '50%', padding: '1rem', margin: '0.5rem' }}
                                onClick={() => {
                                    setPageSelector(false);
                                    setSelected(selectedType.employer);
                                }}
                            >
                                <Typography variant="h5">Find Talent</Typography>
                            </PublicOutlineButton>
                        </Grid>
                    </Box>
                </GenericModalWithoutAction>
            )} */}
        </>
    );
};

export default DemoPage;
