import { Box, Grid, Typography, RadioGroup, FormControlLabel, Radio, FormGroup, Checkbox, Tooltip, Button } from '@mui/material';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useState, useEffect } from 'react';
import { DropResult, DragDropContext, Droppable, DroppableProvided, Draggable, DraggableProvided } from 'react-beautiful-dnd';
import { useSelector, useDispatch } from 'react-redux';
import SearchResultsTable from '../../../../components/SearchResultsTable';
import SkillsAccordion from '../../../../components/filter/skills/Accordin';
import SkillModal from '../../../../components/filter/skills/SkillModal';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { auCitizenshipStatusDropdown, searchAttributeModifier, securityClearanceRadio, seekingDropdown } from '../../../../data/dropDownData';
import { showErrorSnackbar } from '../../../../redux/actions/snackbarAction';
import { selectGeographyData } from '../../../../redux/selectors/geographySelector';
import { selectSkillsData } from '../../../../redux/selectors/skillsSelector';
import { SearchMemberModifier, SearchRequestProfile, SearchRequest } from '../../../../types/search';
import { Skill } from '../../../../types/skill';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { getSkillsFramework } from '../../../../redux/actions/skillsAction';
import { selectPublicSearchCriteria, selectPublicSearchResults } from '../../../../redux/selectors/publicSearchSelector';
import { clearPublicSearch, setPublicSearchCriteria } from '../../../../redux/actions/publicSearchAction';
import { GenericModal } from '../../../../components/modal';

type skillFilter = {
    MANDATORY: DesiredSkill[];
    HIGHLY_DESIRABLE: DesiredSkill[];
    DESIRABLE: DesiredSkill[];
    NICE_TO_HAVE: DesiredSkill[];
};
type DesiredSkill = Skill & { desirability?: SearchMemberModifier };

const defaultSkillFilter: skillFilter = {
    MANDATORY: [],
    HIGHLY_DESIRABLE: [],
    DESIRABLE: [],
    NICE_TO_HAVE: [],
};
const PublicCandidateSearch = () => {
    const [skillModal, setSkillModal] = useState<boolean>(false);
    const [skillFilter, setSkillFilter] = useState<skillFilter>(defaultSkillFilter);
    const [desirability, setDesirability] = useState<SearchMemberModifier>('NICE_TO_HAVE');
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [informationModal, setInformationModal] = useState<boolean>(true);
    const [actionButtonLabel, setActionButtonLabel] = useState<string>('ADD YOUR FIRST SKILL OR EXPERIENCE');
    const [filterOpen, setFilterOpen] = useState({
        filter: true,
        securityClearance: false,
        citizenShip: false,
        employmentModal: false,
        affirmativeAction: false,
    });
    const [filterData, setFilterData] = useState<SearchRequestProfile>({
        securityClearance: [],
        auCitizenshipStatus: [],
        aboriginalOrTorresStrait: false,
        disabled: false,
        genderFemale: false,
        genderNonBinary: false,
        veteranStatus: false,
        defencePartner: false,
        workforceReturner: false,
        seeking: [],
    } as SearchRequestProfile);
    const skills = useSelector(selectSkillsData);
    const searchCriteria = useSelector(selectPublicSearchCriteria);
    const searchSkills = searchCriteria.skills;
    const searchResults = useSelector(selectPublicSearchResults);
    const geography = useSelector(selectGeographyData);
    const dispatch = useDispatch();

    const handleSkillDelete = (item: DesiredSkill) => {
        const newSearchCriteria = { ...searchCriteria, skills: { ...searchCriteria.skills } };
        const { id, desirability } = item;
        if (!desirability) return;
        if (newSearchCriteria.skills && newSearchCriteria.skills[id]) {
            //created new object to avoid mutation
            const updatedSkills = { ...newSearchCriteria.skills };
            delete updatedSkills[id];
            newSearchCriteria.skills = updatedSkills;
        }
        dispatch(setPublicSearchCriteria(newSearchCriteria));
    };

    const handleSkillModalClose = () => {
        setSkillModal(false);
    };

    const handleSkillModalUpdate = (value: DesiredSkill[] | DesiredSkill) => {
        if (Array.isArray(value) && value.length > 0) {
            const newSearchCriteria = { ...searchCriteria } as SearchRequest;
            value.forEach((item) => {
                const { id, desirability } = item;
                if (!desirability) return;
                if (desirability === 'MANDATORY') {
                    newSearchCriteria.skills = {
                        ...newSearchCriteria.skills,
                        [id]: { modifier: desirability, key: 'experience', value: 'ANY' },
                    };
                } else {
                    newSearchCriteria.skills = {
                        ...newSearchCriteria.skills,
                        [id]: { modifier: desirability, key: 'experience' },
                    };
                }
            });
            dispatch(setPublicSearchCriteria(newSearchCriteria));
            setSkillModal(!skillModal);
        } else {
            if (!searchCriteria.skills) {
                searchCriteria.skills = {};
            }
            const skillsIds = Object.keys(searchCriteria.skills);
            const { id, desirability } = value as DesiredSkill;
            if (skillsIds.length > 0) {
                const skillExist = skillsIds.find((item) => item === id);
                if (skillExist) {
                    dispatch(showErrorSnackbar('This skill has already been added. You can amend the weighting from within the table'));
                    return;
                }
            }
            const newSearchCriteria = { ...searchCriteria } as SearchRequest;
            if (!desirability) return;
            if (desirability === 'MANDATORY') {
                newSearchCriteria.skills = {
                    ...searchCriteria.skills,
                    [id]: { modifier: desirability, key: 'experience', value: 'ANY' },
                };
            } else {
                newSearchCriteria.skills = {
                    ...searchCriteria.skills,
                    [id]: { modifier: desirability, key: 'experience' },
                };
            }
            dispatch(setPublicSearchCriteria(newSearchCriteria));
            setSkillModal(!skillModal);
        }
    };

    const handleSecurityClearanceChange = (value: string) => {
        const newSearchCriteria = { ...searchCriteria } as SearchRequest;
        if (!newSearchCriteria.profile) {
            newSearchCriteria.profile = {};
        }
        const checked = value === filterData.securityClearance?.join(',') || false;
        if (!checked) {
            newSearchCriteria.profile = { ...newSearchCriteria.profile, ['securityClearance']: value.split(',') };
        } else {
            newSearchCriteria.profile = { ...newSearchCriteria.profile, ['securityClearance']: [] };
        }
        dispatch(setPublicSearchCriteria(newSearchCriteria));
    };

    const handleCitizenshipStatusCheckbox = (event: any, item: string) => {
        const {
            target: { checked },
        } = event;
        const newSearchCriteria = { ...searchCriteria } as SearchRequest;
        if (checked) {
            if (!newSearchCriteria.profile) {
                newSearchCriteria.profile = {};
            }
            if (!newSearchCriteria.profile.auCitizenshipStatus) {
                newSearchCriteria.profile.auCitizenshipStatus = [];
            }
            newSearchCriteria.profile = { ...newSearchCriteria.profile, ['auCitizenshipStatus']: [...newSearchCriteria.profile.auCitizenshipStatus, item] };
        } else {
            if (!newSearchCriteria.profile) {
                newSearchCriteria.profile = {};
            }
            if (!newSearchCriteria.profile.auCitizenshipStatus) {
                newSearchCriteria.profile.auCitizenshipStatus = [];
            }
            newSearchCriteria.profile = {
                ...newSearchCriteria.profile,
                ['auCitizenshipStatus']: [...newSearchCriteria.profile.auCitizenshipStatus.filter((status) => status !== item)],
            };
        }
        dispatch(setPublicSearchCriteria(newSearchCriteria));
    };
    const handleEmploymentModalCheckbox = (event: any, item: string) => {
        const {
            target: { checked },
        } = event;
        const newSearchCriteria = {
            ...searchCriteria,
            profile: {
                ...searchCriteria.profile,
                seeking: checked
                    ? [...(searchCriteria.profile?.seeking || []), item]
                    : (searchCriteria.profile?.seeking || []).filter((status) => status !== item),
            },
        };

        dispatch(setPublicSearchCriteria(newSearchCriteria));
    };

    // const handleAffirmativeActionCheckbox = (event: any, data: any) => {
    //     const {
    //         target: { checked },
    //     } = event;
    //     const value = data.value as keyof SearchRequestProfile;

    //     const newSearchCriteria = { ...searchCriteria } as SearchRequest;
    //     if (!newSearchCriteria.profile) {
    //         return;
    //     }
    //     newSearchCriteria.profile = { ...newSearchCriteria.profile, [value]: checked };
    //     dispatch(setSearchCriteria(newSearchCriteria));
    // };

    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }
        const {
            draggableId: id,
            source: { index: sourceIndex, droppableId: sourceID },
            destination: { index: destinationIndex, droppableId: destinationID },
        } = result;

        if (sourceIndex === destinationIndex && sourceID === destinationID) {
            return;
        }
        const newSearchCriteria = { ...searchCriteria } as SearchRequest;
        if ((destinationID as SearchMemberModifier) === 'MANDATORY') {
            newSearchCriteria.skills = {
                ...searchCriteria.skills,
                [id]: { modifier: destinationID as SearchMemberModifier, key: 'experience', value: 'ANY' },
            };
        } else {
            newSearchCriteria.skills = {
                ...searchCriteria.skills,
                [id]: { modifier: destinationID as SearchMemberModifier, key: 'experience' },
            };
        }
        dispatch(setPublicSearchCriteria(newSearchCriteria));
    }

    useEffect(() => {
        setTimeout(() => {
            dispatch(getSkillsFramework());
            dispatch(
                setPublicSearchCriteria({
                    profile: {
                        securityClearance: [],
                        auCitizenshipStatus: [],
                    },
                    searchBy: 'public',
                })
            );
        }, 1000);
        setTimeout(() => {
            setInformationModal(false);
        }, 4000);
        return () => {
            dispatch(clearPublicSearch());
        };
    }, [dispatch]);

    useEffect(() => {
        const { profile, skills: searchSkills } = searchCriteria;
        if (profile) {
            let { auCitizenshipStatus, securityClearance } = profile;
            const {
                genderFemale = false,
                genderNonBinary = false,
                workforceReturner = false,
                veteranStatus = false,
                aboriginalOrTorresStrait = false,
                disabled = false,
                defencePartner = false,
                seeking,
            } = profile;
            if (!auCitizenshipStatus) {
                auCitizenshipStatus = auCitizenshipStatusDropdown.map((item) => item.value);
            }
            if (!securityClearance) {
                securityClearance = [];
            }
            setFilterData({
                auCitizenshipStatus,
                securityClearance,
                genderFemale,
                genderNonBinary,
                workforceReturner,
                veteranStatus,
                aboriginalOrTorresStrait,
                disabled,
                defencePartner,
                seeking,
            });
        }
        if (searchSkills) {
            const allSkills = Object.entries(searchSkills);
            const skillFilter = defaultSkillFilter;
            searchAttributeModifier.forEach((item) => {
                const result = [] as DesiredSkill[];
                allSkills.forEach(([id, values]) => {
                    if (item.value === values.modifier) {
                        const selectedSkill = skills.find((skill) => skill.id === id);
                        if (!selectedSkill) {
                            // handle if skill doesn't exist
                            return;
                        }
                        result.push({ ...selectedSkill, desirability: searchSkills[id].modifier } as DesiredSkill);
                    }
                });
                skillFilter[item.value as keyof skillFilter] = result;
            });
            setSkillFilter(skillFilter);
            setActionButtonLabel('ADD ANOTHER SKILL OR EXPERIENCE TO REFINE YOUR SEARCH');
        } else {
            const defaultSkillFilter: skillFilter = {
                MANDATORY: [],
                HIGHLY_DESIRABLE: [],
                DESIRABLE: [],
                NICE_TO_HAVE: [],
            };
            setSkillFilter(defaultSkillFilter);
            setActionButtonLabel('ADD YOUR FIRST SKILL OR EXPERIENCE');
        }
    }, [skills, searchCriteria]);

    const handleInformationModalClose = () => {
        setInformationModal(false);
    };

    const handleInformationModalSuccess = () => {
        handleInformationModalClose();
        setDesirability('HIGHLY_DESIRABLE');
        setSkillModal(true);
    };

    return (
        <Box>
            <Grid container mt={'3rem'} display={'flex'}>
                <Grid item xs={12} md={3} lg={3}>
                    <Typography variant="h5" color={'primary'} ml={'2rem'}>
                        Describe your ideal Candidate
                    </Typography>
                    <Grid item ml={'1rem'} mt={'1rem'}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => {
                                setDesirability('HIGHLY_DESIRABLE');
                                setSkillModal(true);
                            }}
                        >
                            {actionButtonLabel}
                        </Button>
                    </Grid>
                    <Grid ml={'1rem'} mt={'1rem'} mb={'2rem'}>
                        <Typography variant="h6" color={'primary'} mb={'2rem'}>
                            Skills and Experience
                        </Typography>
                        <DragDropContext onDragEnd={onDragEnd}>
                            {skillFilter.MANDATORY && (
                                <Droppable droppableId="MANDATORY">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Mandatory skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('MANDATORY');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>
                                                        <b>Mandatory</b>
                                                    </Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.MANDATORY.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <div
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                            <p className="skill-drag-text">
                                                                <Typography variant="body2">{item.display}</Typography>
                                                            </p>
                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}

                            {skillFilter.HIGHLY_DESIRABLE && (
                                <Droppable droppableId="HIGHLY_DESIRABLE">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Highly Desirable skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('HIGHLY_DESIRABLE');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>
                                                        <b>Highly Desirable</b>
                                                    </Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.HIGHLY_DESIRABLE.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <div
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                            <p className="skill-drag-text">
                                                                <Typography variant="body2">{item.display}</Typography>
                                                            </p>
                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                            {skillFilter.DESIRABLE && (
                                <Droppable droppableId="DESIRABLE">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Desirable skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('DESIRABLE');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>
                                                        <b>Desirable</b>
                                                    </Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.DESIRABLE.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <div
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                            <p className="skill-drag-text">
                                                                <Typography variant="body2">{item.display}</Typography>
                                                            </p>
                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                            {skillFilter.NICE_TO_HAVE && (
                                <Droppable droppableId="NICE_TO_HAVE">
                                    {(provided: DroppableProvided) => (
                                        <div style={{ marginBottom: '1rem' }} ref={provided.innerRef} {...provided.droppableProps}>
                                            <Tooltip title="Add a Nice to Have skill or experience">
                                                <div
                                                    onClick={() => {
                                                        setDesirability('NICE_TO_HAVE');
                                                        setSkillModal(true);
                                                    }}
                                                    className="desirability-title-container"
                                                >
                                                    <Typography>
                                                        <b>Nice To Have</b>
                                                    </Typography>
                                                    <ExpandMoreIcon color="primary" />
                                                </div>
                                            </Tooltip>
                                            {skillFilter.NICE_TO_HAVE.map((item, idx) => (
                                                <Draggable key={item.id} draggableId={item.id} index={idx}>
                                                    {(provided: DraggableProvided) => (
                                                        <div
                                                            className="skill-drag-item"
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <Tooltip title="Drag and drop to reprioritise">
                                                                <i className="fa-solid fa-grip"></i>
                                                            </Tooltip>
                                                            <p className="skill-drag-text">
                                                                <Typography variant="body2">{item.display}</Typography>
                                                            </p>
                                                            <DeleteTwoToneIcon className="cursor-pointer" onClick={() => handleSkillDelete(item)} />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                        </DragDropContext>
                    </Grid>
                    {searchSkills && (
                        <>
                            <Typography variant="h6" color={'primary'} marginLeft={'1rem'}>
                                Other Filters
                            </Typography>
                            <Grid marginLeft={'1rem'} marginTop={'1rem'}>
                                <Grid item>
                                    <SkillsAccordion
                                        titleStyle={{ fontWeight: 'bold' }}
                                        title="Aust. Security Clearance"
                                        open={filterOpen.securityClearance}
                                        onChange={(value) => {
                                            setFilterOpen({ ...filterOpen, securityClearance: value });
                                        }}
                                    >
                                        <>
                                            <Typography variant="caption" color={'GrayText'} fontStyle={'italic'}>
                                                (Includes pending and lapsed clearances)
                                            </Typography>
                                            <RadioGroup sx={{ marginTop: 0 }} value={filterData.securityClearance?.join(',')} name="radio-buttons-group">
                                                {securityClearanceRadio.map((item) => (
                                                    <FormControlLabel
                                                        key={item.label}
                                                        value={item.value}
                                                        control={<Radio onClick={() => handleSecurityClearanceChange(item.value)} size="small" />}
                                                        label={<Typography variant="body2">{item.label}</Typography>}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </>
                                    </SkillsAccordion>
                                    {!filterOpen.securityClearance && (filterData.securityClearance || [])?.length > 0 && (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <i className="fa-regular fa-square-check mr-1 dropdown-icons"></i>
                                            <Typography variant="body2">
                                                {securityClearanceRadio.find((item) => item.value === filterData.securityClearance?.join(','))?.label}
                                            </Typography>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item>
                                    <SkillsAccordion
                                        titleStyle={{ fontWeight: 'bold' }}
                                        title="Australian Citizenship Status"
                                        open={filterOpen.citizenShip}
                                        onChange={(value) => {
                                            setFilterOpen({ ...filterOpen, citizenShip: value });
                                        }}
                                    >
                                        <>
                                            <FormGroup>
                                                {auCitizenshipStatusDropdown.map((item) => (
                                                    <FormControlLabel
                                                        key={item.key}
                                                        control={
                                                            <Checkbox
                                                                onChange={(event) => handleCitizenshipStatusCheckbox(event, item.value)}
                                                                checked={filterData.auCitizenshipStatus?.includes(item.value) || false}
                                                                name={item.value}
                                                            />
                                                        }
                                                        label={<Typography variant="body2">{item.label}</Typography>}
                                                    />
                                                ))}
                                            </FormGroup>
                                        </>
                                    </SkillsAccordion>

                                    {!filterOpen.citizenShip && (
                                        <FormGroup>
                                            {(filterData?.auCitizenshipStatus || []).map((item) => (
                                                <div key={item} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <i className="fa-regular fa-square-check mr-1 dropdown-icons"></i>
                                                    <Typography variant="body2">{item.replace(/_/g, ' ')}</Typography>
                                                </div>
                                            ))}
                                        </FormGroup>
                                    )}
                                </Grid>
                                <Grid item>
                                    <SkillsAccordion
                                        titleStyle={{ fontWeight: 'bold' }}
                                        title="Employment Models"
                                        open={filterOpen.employmentModal}
                                        onChange={(value) => {
                                            setFilterOpen({ ...filterOpen, employmentModal: value });
                                        }}
                                    >
                                        <FormGroup>
                                            {seekingDropdown.map((data) => (
                                                <FormControlLabel
                                                    key={data}
                                                    control={
                                                        <Checkbox
                                                            onClick={(event) => {
                                                                handleEmploymentModalCheckbox(event, data);
                                                            }}
                                                            checked={filterData.seeking?.includes(data) || false}
                                                            name={data}
                                                        />
                                                    }
                                                    label={<Typography variant="body2">{data}</Typography>}
                                                />
                                            ))}
                                        </FormGroup>
                                    </SkillsAccordion>
                                    {!filterOpen.employmentModal && (
                                        <FormGroup>
                                            {(filterData.seeking || []).map((item) => (
                                                <div key={item} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <i className="fa-regular fa-square-check mr-1 dropdown-icons"></i>
                                                    <Typography variant="body2">{item.replace(/_/g, ' ')}</Typography>
                                                </div>
                                            ))}
                                        </FormGroup>
                                    )}
                                </Grid>
                                {/* <Grid item>
                            <SkillsAccordion
                                title="Affirmative Action Group"
                                open={filterOpen.affirmativeAction}
                                onChange={(value) => {
                                    setFilterOpen({ ...filterOpen, affirmativeAction: value });
                                }}
                            >
                                <FormGroup>
                                    {affirmativeActionFilter.map((data) => (
                                        <FormControlLabel
                                            key={data.key}
                                            control={
                                                <Checkbox
                                                    onClick={(event) => {
                                                        handleAffirmativeActionCheckbox(event, data);
                                                    }}
                                                    checked={
                                                        filterData[
                                                            data.value as keyof Omit<
                                                                SearchRequestProfile,
                                                                'auCitizenshipStatus' | 'securityClearance' | 'seeking' | 'candidateId'
                                                            >
                                                        ] || false
                                                    }
                                                    name={data.value}
                                                />
                                            }
                                            label={data.label}
                                        />
                                    ))}
                                </FormGroup>
                            </SkillsAccordion>
                        </Grid> */}
                            </Grid>
                        </>
                    )}
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                    <Grid item pl={'1rem'} pr={'1rem'}>
                        <SearchResultsTable
                            columns={searchCriteria}
                            rows={searchResults}
                            skills={skills}
                            geography={geography}
                            selectionModel={selectionModel}
                            setSelectionModel={setSelectionModel}
                            setSearchCriteria={setPublicSearchCriteria}
                            searchCriteriaObject={{ ...searchCriteria }}
                            searchBy="public"
                        />
                    </Grid>
                </Grid>
            </Grid>

            {skillModal && <SkillModal selectedDesirability={desirability} handleClose={handleSkillModalClose} handleSuccess={handleSkillModalUpdate} />}

            {informationModal && (
                <GenericModal
                    handleClose={handleInformationModalClose}
                    handleSuccess={handleInformationModalSuccess}
                    successLabel="ADD YOUR FIRST SKILL OR EXPERIENCE"
                    cancelLabel="IGNORE"
                >
                    <>
                        <Typography>Currently displaying {searchResults.length} active candidates.</Typography>
                        <br />
                        <Typography>
                            To refine your search, add a skill or experience to the left. You can drag and drop the skills to prioritise them.
                        </Typography>
                    </>
                </GenericModal>
            )}
        </Box>
    );
};
export default PublicCandidateSearch;
