import { useState, useEffect } from 'react';
import NovoSnackbar from './components/NovoSnackbar';
import { useConfig } from './config/ConfigProvider';

import './App.scss';
import Loader from './components/loader';

import DemoPage from './Containers/Pages/Public/demo';

function App() {
    const { config, error } = useConfig();
    const [configLoadingState, setConfigLoadingState] = useState<'loading' | 'ready' | 'error'>('loading');

    useEffect(() => {
        if (error) {
            setConfigLoadingState('error');
            return;
        }

        if (config) {
            setConfigLoadingState('ready');
        }
    }, [config, error]);

    if (configLoadingState === 'loading') {
        return <Loader />;
    }

    if (configLoadingState === 'error') {
        return (
            <div>
                <p style={{ color: 'red', textAlign: 'center' }}>Error while fetching global config </p>
            </div>
        );
    }

    return (
        <>
            <NovoSnackbar />

            <div className="tailwind-container">
                <DemoPage />
            </div>
        </>
    );
}

export default App;
